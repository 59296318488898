.icon-selector-menu {
    .MuiPaper-root {
        padding: 16px;
        display: inline-block;
        width: 70vw !important;
        height: 70vh !important;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

        .scroll-area {
            position: absolute;
            top: 80px;
            left: 0px;
            display: inline-block;
            width: 100%;
            height: calc(100% - 80px);
            padding: 16px;
            overflow-y: auto;

            .icon-grid {
                .icon-item {
                    display: inline-block;
                    width: 100%;
                    height: 64px;
                    position: relative;
                    margin-bottom: 8px;
                    text-align: center;
                    

                    &.selected svg {
                        border-width: 2px;
                        padding: 10px;
                    }

                    span {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        margin: 20px;
                    }

                    svg {
                        border-width: 0px;
                        border-style: solid;
                        padding: 12px;
                        box-sizing: border-box;
                        width: 64px;
                        height: 64px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: background-color 0.2s;
                        border-radius: 4px;
                        cursor: pointer;

                        &:hover {
                            background-color: #ffffff33;
                        }
                    }
                }
            }
        }
    }
}