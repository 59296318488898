.settings-grid {
    padding: 8px;

    .settings-panel {
        position: relative;
        padding: 16px;
        padding-top: 64px;
        box-sizing: border-box;
        display: inline-block;
        height: fit-content;
        width: 100%;
        overflow: hidden;

        .panel-title {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 48px;
            background-color: #00000044;
            user-select: none;
            top: 0px;
            left: 0px;

            .refresh-btn {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translate(0, -50%);

                svg {
                    transition: transform 0.2s !important;
                }

                &:hover {
                    svg {
                        transform: rotate(30deg);
                    }
                }
            }

            .icon {
                position: absolute;
                top: calc(50% + 2px);
                left: 12px;
                transform: translate(0, -50%);
            }

            .text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .arrow {
                    vertical-align: text-bottom;
                    margin-top: 11px;
                    opacity: 0.5;
                }

                .content {
                    font-size: 20px;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            & {
                height: fit-content;
            }
        }

        @media screen and (min-width: 1201px) {
            & {
                height: calc(100vh - 104px);
                min-height: 512px;
            }
        }

        .panel-content {
            display: inline-block;
            width: 100%;
            height: 100%;
            overflow-y: auto;

            /* User Panel */
            .user-item {
                position: relative;
                padding: 12px;
                padding-left: 16px;
                padding-right: 48px;
                .username {
                    font-size: 20px;
                }

                .email {
                    opacity: 0.5;
                }

                .delete {
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translate(0, -50%);
                }

                .current-user {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translate(0, -50%);
                    color: gold;
                }
            }

            /* Location Panel */
            .location-item {
                position: relative;
                padding: 12px;
                padding-left: 16px;
                padding-right: 96px;
                box-sizing: border-box;

                * {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &.icon {
                    padding-left: 48px;

                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 12px;
                        transform: translate(0, -50%);
                    }
                }

                .parent {
                    font-size: 12px;
                    opacity: 0.6;
                    margin-top: -4px;
                    pointer-events: none;
                    width: 100%;
                }

                .buttons {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translate(0, -50%);
                }
            }

            /* Category Panel */
            .category-item {
                position: relative;
                padding: 12px;
                padding-left: 16px;
                padding-right: 96px;
                box-sizing: border-box;
                overflow-x: hidden;

                * {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &.icon {
                    padding-left: 48px;

                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 12px;
                        transform: translate(0, -50%);
                    }
                }

                .buttons {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translate(0, -50%);
                }

                .flags {
                    opacity: 0.8;
                    margin-top: 4px;
                    width: fit-content;
                }
            }
        }
    }
}

.create-edit-category-dialog {
    .flag-input {
        position: relative;
    }
}
