.index-content {
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 16px;

    .search {
        position: relative;
    }

    .content-area {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        padding: 4px;
        margin-top: -32px;
        width: 100%;
        height: calc(100% - 72px);
        overflow: auto;

        .location-item {
            padding: 16px;
            position: relative;
            box-sizing: border-box;

            & > .MuiStack-root {
                padding: 8px;
            }

            .expand-btn {
                position: absolute;
                top: 8px;
                right: 8px;
                transition: transform 0.2s;

                &.expanded {
                    transform: rotate(180deg);
                }
            }

            .link-btn {
                position: absolute;
                top: 8px;
                right: 56px;
            }

            .location-children {
                padding: 4px;
            }
        }

        .service-item {
            position: relative;
            padding: 16px;
            padding-left: 48px;
            transition: border-color 0.2s;
            border: 1px solid #00000000;
            box-sizing: border-box;
            cursor: pointer;

            &.voting {
                padding-left: 8px;
                padding-right: 8px !important;

                .problem-box {
                    position: relative;
                    padding: 4px;
                    overflow: hidden;

                    .no-problems-container {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: 32px;
                        margin-bottom: -6px;
                        user-select: none;
                        pointer-events: none;
                        opacity: 0.6;

                        .no-problems {
                            position: absolute;
                            width: fit-content;
                            white-space: nowrap;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .problem-scroll {
                        overflow: hidden;

                        .problem-stack {
                            overflow: visible;
                            white-space: nowrap;
                            display: inline-block;
                            width: fit-content;
                            height: 100%;
                        }
                    }
                }

                .vote-chart-container {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 384px;
                    padding: 4px;
                    box-sizing: border-box;
                    background-color: #eee;
                    user-select: none;
                    pointer-events: none;
                }
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translate(0, -50%);
                z-index: 10;
            }

            .service-name {
                font-size: 16px;
                z-index: 10;
            }

            .category-name {
                font-size: 12px;
                opacity: 0.6;
                z-index: 10;
            }

            .service-actions {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translate(0, -50%);
                z-index: 10;
            }

            &.admin {
                padding-right: 48px;
            }

            &:hover .vote-bar {
                opacity: 0.25;
            }

            .vote-bar {
                pointer-events: none;
                position: absolute;
                top: 4px;
                left: 4px;
                display: inline-block;
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                border-radius: 4px;
                overflow: hidden;
                background-color: #00000044;
                opacity: 0.15;
                z-index: 0;
                transition: opacity 0.2s;

                .bar {
                    display: inline-block;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    transition: width 0.2s;

                    &.working {
                        left: 0px;
                    }

                    &.not-working {
                        right: 0px;
                    }
                }
            }
        }

        .service-item-create {
            position: relative;
            padding: 12px;

            .category-select {
                width: calc(max(256px, 25%) + 0px);

                @media screen and (max-width: 800px) {
                    & {
                        width: 100%;
                    }
                }
            }

            .create-btn {
                min-width: 41px;
            }
        }
    }
}
